import React from 'react'
import Layout from '../components/layout'
import './mentions-legales.scss'

export default function MentionsLegales() {

    return (
        <div>
            <Layout>

                <div className="modules page_mentions_legales">
                    <div className="module_1">
                        <div className="center_absolute titres">
                            <h2>Mentions Légales</h2>
                        </div>
                        <div className="overlay_absolute_6"></div>
                    </div>

                    <section className="module_2 width_1200">
                        <h2>Mentions légales</h2>
                        <h3>Responsable de publication</h3>
                        <p><b>Responsable :</b> David DEPLAN</p>
                        <p><b>Nom commercial :</b> SAS Cité de l'Evasion - Capital : 15 000 €</p>
                        <p><b>Statut juridique :</b> Société par actions simplifiée</p>
                        <p><b>Adresse :</b> 725, boulevard Barrier - 73100 - AIX LES BAINS</p>
                        <p><b>N° SIRET :</b> 522 672 195 000 16</p>
                        <p><b>Code APE :</b> Activités des voyagistes (7912Z)</p>
                        <p><b>TVA :</b> FR 54522672195</p>
                        <p><b>Licence :</b> IM 073100035</p>
                        <p><b>Garant :</b> APST - 15 Avenue Carnot - 75017 PARIS</p>
                        <p><b>Assureur :</b> GENERALI IARD - 2 Rue Pillet Will - 75009 PARIS - Contrat AL 959661</p>

                        <h3>Conception et hebergement</h3>
                        <p><b>Conception :</b> Topastuces Informatique, siège social au <i>95 avenue des 16emes Jeux Olympiques d'hiver - 73200 Albertville</i></p>
                        <p><b>Nom de domaine :</b> OVH SAS, siège social au <i>2 rue Kellermann - 59100 Roubaix - France</i></p>
                        <p><b>Hébergement :</b> Netlify, siège social au <i>2325 3rd Street, Suite 215, San Francisco, California 94107</i></p>
                    
                        <h3>Condition Générales d'Utilisation</h3>
                        <h4>Cookies</h4>
                        <p>Les informations obtenues par le biais du formulaire de contact, des cookies et de l’outil d’analyse de trafic sont destinées au seul usage de David DEPLAN. Ces informations sont réservées à un usage d’analyse et ne pourront être vendues ou mises à la disposition de tierces parties.
                        Les données enregistrés par l'utilisateur lors de l'inscription à la newsletter seront gardées jusqu'a ce que l'utilisateur se désabonne via le lien de désabonnement situé en bas de la newsletter.
                        Granito des Savoie se réserve le droit de modifier, à tout moment et sans préavis, les présentes CGU afin de les adapter aux évolutions du Site et/ou de son exploitation. Un email sera envoyé pour prévenir les utilisateurs enregistrés des nouvelles CGU.</p>
                        <h4>Déclaration de la CNIL</h4>
                        <p>En application de la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, vous disposez des droits d’opposition (art. 26), d’accès (art. 34 à 38) et de rectification (art. 36) des données vous concernant. Pour l’exercer, vous pouvez faire votre demande à David DEPLAN, responsable de publication du site www.citedelevasion.com via le formulaire de contact de ce site.</p>
                    </section>
                </div>

            </Layout>
        </div>
    )
}